@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap');

.wrapper {
    background-color: #fafafa;
    display: grid;
    justify-content: center;
    align-content: center;
    width: 100%;
    align-items: center;
    height: 100vh;
  }
  
  .box {
    border: 1px solid #dfdfdf;
    background-color: #fff;
  }
  
  .g-btn {
    width: 100% !important;
    height: 40px !important;
    line-height: 40px !important;
    font-size: 15px !important;
  }
  .g-btn > div,
  .g-btn > div > svg {
    width: 40px !important;
    height: 38px !important;
  }